export default {
  routes: [
    {
      path: "/beneficiaries",
      name: "beneficiaries",
      redirect: "/",
      meta: {
        title: "user_management.beneficiaries",
        // permissions: ["can_access_beneficiaries", "can_list_beneficiaries"],
        roles: [""],
        requiredModules: null,
      },
      children: [
        {
          path: "",
          name: "beneficiaries-list",
          component: () => import("./BeneficiariesList.vue"),
          meta: {
            roles: [""],
            // permissions: [
            //   "can_access_beneficiaries",
            //   "can_list_beneficiaries",
            // ],
          },
        },
        {
          path: "create",
          name: "create-beneficiaries",
          component: () => import("./NewBeneficiary.vue"),
          meta: {
            roles: [""],
            // permissions: ["can_create_beneficiaries"],
          },
        },
        {
          path: ":id/edit",
          name: "edit-beneficiaries",
          props: true,
          component: () => import("./NewBeneficiary.vue"),
          meta: {
            roles: [""],
            // permissions: ["can_update_unit_residents"],
            isEdit: true,
          },
        },
        {
          path: ":id",
          name: "beneficiaries-info",
          props: true,
          meta: {
            // permissions: ["can_view_beneficiaries"],
          },
          component: () => import("./WrapperBeneficiaries.vue"),
          redirect: { name: "beneficiaries-details" },
          children: [
            {
              path: "details",
              name: "beneficiaries-details",
              props: true,
              component: () => import("./BeneficiaryDetails.vue"),
              meta: {
                // permissions: ["can_view_unit_residents"],
              },
            },
            {
              path: "beneficiaries-logs",
              name: "beneficiaries-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                roles: [""],
                // permissions: ["can_view_beneficiaries"],
              },
            },
          ],
        },
      ],
    },
  ],
};
