export default {
  routes: [
    {
      path: "/vendor-users",
      component: () => import("./WrapperUsers.vue"),
      redirect: { name: "vendor-users" },
      meta: {
        title: "Admins",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "vendor-users",
          component: () => import("./UsersList.vue"),
          meta: {
            title: "Admins",
            roles: [""],
          },
        },

        {
          path: "create",
          name: "vendor-users-create",
          component: () => import("./VendorUserCreate.vue"),
          meta: {
            title: "Admins",
            roles: [""],
            isEdit: false,
          },
        },
        {
          path: ":id/edit",
          name: "vendor-users-edit",
          component: () => import("./VendorUserCreate.vue"),
          meta: {
            title: "Admins",
            roles: [""],
            isEdit: true,
          },
        },
      ],
    },
  ],
};
