import communicationVendorRoute from "./Communication/communication.vendor.route";
import companiesVendorRoute from "./VendorCompanies/companies.vendor.route";
import workOrdersVendorRoute from "./WorkOrdersVendor/work.orders.vendor.route";
import invoicesVendorRoute from "./VendorInvoices/invoices.vendor.route";
import teamsVendorRoute from "./Teams/teams.route.js";
import usersVendorRoute from "./Users/users.vendor.route";
export default {
  routes: [
    ...workOrdersVendorRoute.routes,
    ...communicationVendorRoute.routes,
    ...companiesVendorRoute.routes,
    ...invoicesVendorRoute.routes,
    ...teamsVendorRoute.routes,
    ...usersVendorRoute.routes,
  ],
};
