import aside from "./aside";
import errors from "./errors";
import global from "./global";
import login from "./login";
import dashboard from "./Dashboard/dashboard.json";

//User Management
import user from "./UserManagement/users.json";
import property_owners from "./UserManagement/propertyOwners.json";
import unit_residents from "./UserManagement/unitResidents.json";
import beneficiaries from "./UserManagement/beneficiaries.json";

// Locations
import allLocations from "./Locations/allLocations.json";
import site from "./Locations/site.json";
import properties from "./Locations/properties.json";
import units from "./Locations/units.json";

// Vendors and Teams
import vendors from "./VendorTeams/vendors.json";
import teams from "./VendorTeams/teams.json";

// Assets Management
import assets from "./AssetManagement/assets.json";
import parts from "./AssetManagement/spareParts.json";

// Library
import pricebook from "./Library/pricebook.json";
import procedures from "./Library/procedures.json";
import template from "./Library/template.json";

// Accounting
import accounting from "./Accounting/accounting.json";

// Marketplace
import marketplace from "./Marketplace";
// Settings
import settings from "./Settings";

// Administration
import administration from "./Administration/administration.json";
// Work Orders
import workOrder from "./WorkOrders/workOrder.json";

// communication
import communication from "./communication/communication.json";
import notification from "./communication/notification.json";

// request
import requests from "./Requests/requests.json";

// subscription
import subscription from "./Subscription/subscription.json";

export default {
  ...global,
  ...errors,
  ...aside,
  ...login,
  ...dashboard,
  ...allLocations,
  ...site,
  ...properties,
  ...units,
  ...user,
  ...property_owners,
  ...unit_residents,
  ...beneficiaries,
  ...vendors,
  ...teams,
  ...assets,
  ...parts,
  ...pricebook,
  ...procedures,
  ...template,
  ...accounting,
  ...marketplace,
  ...settings,
  ...administration,
  ...workOrder,
  ...communication,
  ...requests,
  ...subscription,
  ...notification,
};
