import showTeamsRoute from "./show.teams.route";

export default {
  routes: [
    {
      path: "/teams",
      name: "vendor-teams-index",
      component: () => import("./TeamsIndex.vue"),
      meta: {
        title: "Teams",
        roles: [""],
      },
      children: [
        {
          path: "",
          name: "vendor-teams",
          component: () => import("./WrapperTeams.vue"),
          redirect: { name: "vendor-teams-list-view" },
          children: [
            {
              path: "calendar",
              name: "vendor-teams-calendar-view",
              component: () =>
                import(
                  "@/views/Dashboard/WorkOrders/CorrectiveOrders/WrapperCorrective.vue"
                ),
              meta: {
                roles: [""],
              },
            },
            {
              path: "list",
              name: "vendor-teams-list-view",
              component: () => import("./TeamsList.vue"),
              meta: {
                roles: [""],
              },
            },
            {
              path: "cards",
              name: "vendor-teams-cards-view",
              component: () => import("./TeamsListCards.vue"),
              meta: {
                roles: [""],
              },
            },
          ],
        },
        {
          path: "create",
          name: "vendor-create-teams",
          component: () => import("./CreateTeam.vue"),
          meta: {},
        },
        {
          path: "edit/:id",
          name: "vendor-edit-teams",
          component: () => import("./CreateTeam.vue"),
          meta: {
            isEdit: true,
          },
        },
        {
          path: "review/:id",
          name: "vendor-review-teams",
          component: () => import("./BeforeCreateMember.vue"),
          meta: {},
        },
        {
          path: "create-members/:id",
          name: "vendor-create-teams-members",
          component: () => import("./CreateTeamMembers.vue"),
          meta: {},
        },
        {
          path: "edit-members/:id",
          name: "vendor-edit-teams-members",
          component: () => import("./CreateTeamMembers.vue"),
          meta: {
            isEdit: true,
          },
        },
        {
          path: "",
          name: "vendor-show-teams-index",
          component: () => import("./IndexShowTeams.vue"),
          redirect: { name: "vendor-show-teams" },
          children: [...showTeamsRoute.routes],
          meta: {},
        },
      ],
    },
  ],
};
